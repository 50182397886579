@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

@layer base {
  body {
    @apply font-body px-5 text-sm font-medium;
  }
}
@layer components {
  .container {
    max-width: 1440px;
    @apply mx-auto;
  }
}

.zoom-enter .content {
  opacity: 0;
  transform: scale(0.5);
}
.zoom-enter-active .content {
  opacity: 1;
  transform: scale(1);
}
.zoom-exit .content {
  opacity: 1;
}
.zoom-exit-active .content {
  opacity: 0;
  transform: scale(0.5);
}
